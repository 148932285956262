<template>
  <v-container>
    <v-row class="d-flex justify-center">
      <v-col cols="12">
        <page-header>{{ $t('values.values') }}</page-header>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center">
      <v-col cols="12">
        <page-header-subtitle>{{ $t('values.subtitle') }}</page-header-subtitle>
      </v-col>
    </v-row>

    <v-row class="d-flex justify-center my-8">
      <v-col class="d-flex" cols="12" sm="6" v-for="(value, index) in values" :key="index">
        <v-card shaped class="lighten-4 flex" :class="value.color">
          <v-card-text>
            <div class="text-lowercase">{{ $t('values.' + value.type) }}</div>
            <p class="display-1 text--primary text-lowercase">{{ $t('values.' + value.value) }}</p>
            <span class="text-lowercase">{{ $t('values.' + value.meaning) }}</span>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-text>
            <span class="body-2 text--primary">{{ $t('values.' + value.text) }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader';
import PageHeaderSubtitle from '@/components/PageHeaderSubtitle';

export default {
  name: 'Values',
  components: {
    PageHeader,
    PageHeaderSubtitle,
  },
  data: function () {
    return {
      values: [
        {
          type: 'adjective',
          color: 'purple',
          value: 'value1',
          meaning: 'value1_meaning',
          text: 'value1_text',
        },
        {
          type: 'adjective',
          color: 'green',
          value: 'value2',
          meaning: 'value2_meaning',
          text: 'value2_text',
        },
        {
          type: 'adjective',
          color: 'amber',
          value: 'value3',
          meaning: 'value3_meaning',
          text: 'value3_text',
        },
        {
          type: 'adjective',
          color: 'blue',
          value: 'value4',
          meaning: 'value4_meaning',
          text: 'value4_text',
        },
      ]
    }
  },
}
</script>
<style scoped>

</style>
